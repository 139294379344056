.sf-menu,
.sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none
}

.sf-menu li {
    position: relative
}

.sf-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99
}

.sf-menu > li {
    float: left
}

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
    display: block
}

.sf-menu a {
    display: block;
    position: relative
}

.sf-menu ul ul {
    top: 0;
    left: 100%
}

.sf-menu {
    float: left;
    margin-bottom: 1em
}

.sf-menu ul {
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .2);
    min-width: 12em;
    *width: 12em
}

.sf-menu a {
    border-left: 1px solid #fff;
    border-top: 1px solid #ececec;

    padding: .75em 1em;
    text-decoration: none;
    zoom: 1
}

.sf-menu a {
    color: #13a
}

.sf-menu li {
    white-space: nowrap;
    *white-space: normal;
    -webkit-transition: background .2s;
    transition: background .2s
}


.sf-menu li:hover,
.sf-menu li.sfHover {

    -webkit-transition: none;
    transition: none
}

.sf-arrows .sf-with-ul {
    padding-right: 2.5em;
    *padding-right: 1em
}

.sf-arrows .sf-with-ul:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -3px;
    height: 0;
    width: 0;
    border: 5px solid transparent;
    border-top-color: #dfeeff;
    border-top-color: rgba(255, 255, 255, .5)
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
    border-top-color: white
}

.sf-arrows ul .sf-with-ul:after {
    margin-top: -5px;
    margin-right: -3px;
    border-color: transparent;
    border-left-color: #dfeeff;
    border-left-color: rgba(255, 255, 255, .5)
}

.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
    border-left-color: white
}